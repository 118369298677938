html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  gap: 3rem;
  height: 100vh; /* Full viewport height */
  text-align: center; /* Centers image and video horizontally */
}

img {
  position: relative;
  max-width: 10%; /* Adjust this to make the image smaller */
  left: 1rem;
  height: auto; /* Maintain aspect ratio */
}

video {
  max-width: 100%; /* Ensures the video scales properly */
  max-height: 60vh; /* Limits video height to fit within the screen */
}

/* Media Query for screens 768px and below (tablets and mobile phones) */
@media (max-width: 768px) {
  img {
    max-width: 20%; /* Make the image larger on smaller screens */
  }

  video {
    max-width: 100vw; /* Video remains full-width */
  }
}

/* Media Query for screens 480px and below (mobile phones) */
@media (max-width: 480px) {
  img {
    left: 0.5rem;
    max-width: 30%; /* Make the image even larger on mobile screens */
  }

  video {
    max-width: 100vw; /* Video remains full-width */
  }
}
